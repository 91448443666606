import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Form from "../components/form"

import * as style from "./index.module.scss"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <>
      <div className={style.content}>
        <h1>Contact</h1>
        <Form field1="name" field2="email" />
      </div>
    </>
  </Layout>
)

export default ContactPage
